import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CookieBanner from './components/CookieBanner';
import CookiePolicy from './components/CookiePolicy';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Helmet } from 'react-helmet-async';
import NavigationBar from './components/Navbar';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider } from './components/AuthContext';

const HomePage = lazy(() => import('./components/HomePage/HomePage'));
const AdsPage = lazy(() => import('./components/AdsPage/AdsPage'));
const Register = lazy(() => import('./components/Register'));
const Login = lazy(() => import('./components/Login'));
const AdminLogin = lazy(() => import('./components/AdminLogin'));
const UserDashboard = lazy(() => import('./components/UserDashboard/UserDashboard'));
const AdminDashboard = lazy(() => import('./components/AdminDashboard/AdminDashboard'));
const ManageUsers = lazy(() => import('./components/ManageUsers/ManageUsers'));
const ManageAds = lazy(() => import('./components/ManageAds/ManageAds'));
const ManageCategories = lazy(() => import('./components/ManageCategories/ManageCategories'));
const ManageSubcategories = lazy(() => import('./components/ManageSubcategories/ManageSubcategories'));
const UserManageAds = lazy(() => import('./components/UserManageAds/UserManageAds'));
const VisitStatistics = lazy(() => import('./components/Statistics/VisitStatistics'));
const ReservationStatistics = lazy(() => import('./components/Statistics/ReservationStatistics'));
const UserStats = lazy(() => import('./components/UserStats/UserStats'));
const Notifications = lazy(() => import('./components/Notifications'));
const ReservationDetails = lazy(() => import('./components/ReservationDetails'));
const AutoRenewAds = lazy(() => import('./components/AutoRenewAds'));
const HighlightedAds = lazy(() => import('./components/HighlightedAds'));
const Contact = lazy(() => import('./components/Contact'));
const ResetPassword = lazy(() => import('./components/ResetPassword'));
const EditProfile = lazy(() => import('./components/EditProfile'));
const EditAdminProfile = lazy(() => import('./components/EditAdminProfile'));

// Novije komponente za upravljanje reklamama (promocijama)
const AddPromotion = lazy(() => import('./components/Promotions/AddPromotion'));
const EditPromotion = lazy(() => import('./components/Promotions/EditPromotion'));
const DeletePromotion = lazy(() => import('./components/Promotions/DeletePromotion'));
const PromotionsList = lazy(() => import('./components/Promotions/PromotionsList'));

function App() {
  return (
    <AuthProvider>
      <PayPalScriptProvider options={{
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
        "merchant-id": process.env.REACT_APP_PAYPAL_MERCHANT_ID,
        currency: "EUR",
        components: "buttons"
      }}>
        <Router>
          {/* Tvoj navbar ili slično */}
          <CookieBanner /> 
          <Helmet>
            <title>Codetravel - Najbolja turistička platforma u BiH</title>
            <meta name="description" content="Otkrijte najbolje turističke destinacije i smještaje u Bosni i Hercegovini." />
            <meta property="og:image" content="https://codetravel.ba/og-image.jpg" />
            <meta property="og:title" content="Codetravel - Vaša platforma za turističke usluge" />
            <meta property="og:description" content="Pronađite idealne turističke ponude i smještaje širom BiH." />
            <meta property="og:url" content="https://codetravel.ba" />
            <meta property="og:type" content="website" />
            <meta name="robots" content="index, follow" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Codetravel - Ekskluzivne turističke usluge" />
            <meta name="twitter:description" content="Istražite najbolje destinacije i smještaje u BiH." />
            <meta name="twitter:image" content="https://codetravel.ba/og-image.jpg" />
            <link rel="canonical" href="https://codetravel.ba" />
            <script type="application/ld+json">
              {`
                {
                  "@context": "https://schema.org",
                  "@type": "Organization",
                  "name": "Codetravel",
                  "url": "https://codetravel.ba",
                  "logo": "https://codetravel.ba/logo.png",
                  "sameAs": [
                    "https://www.facebook.com/codetravel",
                    "https://www.instagram.com/codetravel"
                  ]
                }
              `}
            </script>
          </Helmet>
          <NavigationBar />
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/cookie-policy" element={<CookiePolicy />} />
              <Route path="/ads" element={<AdsPage />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/admin/login" element={<AdminLogin />} />
              <Route path="/profile" element={<PrivateRoute role="user" />}>
                <Route path="" element={<EditProfile />} />
              </Route>
              <Route path="/admin/profile" element={<PrivateRoute role="administrator" />}>
                <Route path="" element={<EditAdminProfile />} />
              </Route>
              <Route path="/dashboard" element={<PrivateRoute role="user" />}>
                <Route path="" element={<UserDashboard />} />
                <Route path="manage-ads" element={<UserManageAds />} />
                <Route path="stats" element={<UserStats />} />
                <Route path="notifications" element={<Notifications />} />
                <Route path="reservation-details" element={<ReservationDetails />} />
                <Route path="auto-renew-ads" element={<AutoRenewAds />} />
                <Route path="highlighted-ads" element={<HighlightedAds />} />
              </Route>
              <Route path="/admin/dashboard" element={<PrivateRoute role="administrator" />}>
                <Route path="" element={<AdminDashboard />} />
              </Route>
              <Route path="/admin/manage-users" element={<PrivateRoute role="administrator" />}>
                <Route path="" element={<ManageUsers />} />
              </Route>
              <Route path="/admin/manage-ads" element={<PrivateRoute role="administrator" />}>
                <Route path="" element={<ManageAds />} />
              </Route>
              <Route path="/admin/manage-categories" element={<PrivateRoute role="administrator" />}>
                <Route path="" element={<ManageCategories />} />
              </Route>
              <Route path="/admin/manage-subcategories" element={<PrivateRoute role="administrator" />}>
                <Route path="" element={<ManageSubcategories />} />
              </Route>
              <Route path="/admin/statistics/visits" element={<PrivateRoute role="administrator" />}>
                <Route path="" element={<VisitStatistics />} />
              </Route>
              <Route path="/admin/statistics/reservations" element={<PrivateRoute role="administrator" />}>
                <Route path="" element={<ReservationStatistics />} />
              </Route>
              {/* Nove rute za promocije */}
              <Route path="/admin/promotions" element={<PrivateRoute role="administrator" />}>
                <Route path="" element={<PromotionsList />} />
                <Route path="create" element={<AddPromotion />} />
                <Route path="edit/:id" element={<EditPromotion />} />
                <Route path="delete/:id" element={<DeletePromotion />} />
              </Route>
              <Route path="/contact" element={<Contact />} />
              <Route path="/reset-password" element={<ResetPassword />} />
            </Routes>
          </Suspense>
        </Router>
      </PayPalScriptProvider>
    </AuthProvider>
  );
}

export default App;
