import React, { useState, useEffect } from 'react';
import './CookieBanner.css';

/**
 * Dinamičko učitavanje Google Analytics skripte
 * Tek se učitava nakon što korisnik dozvoli analitičke kolačiće.
 */
const loadAnalytics = () => {
  const gaScript = document.createElement('script');
  gaScript.src = 'https://www.googletagmanager.com/gtag/js?id=479177746';
  gaScript.async = true;
  document.body.appendChild(gaScript);

  gaScript.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag(){ window.dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', '479177746');
  };
};

/**
 * (Opcionalno) Dinamičko učitavanje marketinških skripti 
 * npr. Facebook Pixel, Google Ads, itd.
 */
const loadMarketing = () => {
  // Primjer: Facebook Pixel (zamijenite svojim kodom)
  /*
  !function(f,b,e,v,n,t,s){
    if(f.fbq)return; n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n; n.push=n; n.loaded=!0; n.version='2.0';
    n.queue=[]; t=b.createElement(e); t.async=!0;
    t.src=v; s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)
  }(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', 'VAŠ_PIXEL_ID');
  fbq('track', 'PageView');
  */
};

/* Pomoćna funkcija za čitanje vrijednosti kolačića */
function getCookieValue(name) {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  return match ? match[2] : null;
}

/* Pomoćna funkcija za spremanje preferenci u kolačić (1 godinu) */
function savePreferences(prefs) {
  const jsonString = JSON.stringify(prefs);
  document.cookie = `cookieConsentAdvanced=${jsonString}; path=/; max-age=31536000`;
}

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  // Stanje za preference korisnika (analitički, marketinški)
  const [preferences, setPreferences] = useState({
    analytics: false,
    marketing: false,
  });

  // Učitavanje preferenci iz kolačića (ako postoji)
  useEffect(() => {
    const cookieValue = getCookieValue('cookieConsentAdvanced');
    if (cookieValue) {
      try {
        // Očekujemo JSON string, npr. {"analytics":true,"marketing":false}
        const parsed = JSON.parse(cookieValue);
        setPreferences(parsed);

        // Ako je analytics=true, učitajmo GA
        if (parsed.analytics) {
          loadAnalytics();
        }
        // Ako je marketing=true, učitajmo marketinške skripte
        if (parsed.marketing) {
          loadMarketing();
        }

        // Korisnik već ima postavke -> ne prikazujemo banner
        setShowBanner(false);
      } catch (err) {
        console.error('Greška pri parsiranju cookieja:', err);
        // Ako dođe do greške, prikazujemo banner
        setShowBanner(true);
      }
    } else {
      // Nema kolačića -> prikaži banner
      setShowBanner(true);
    }
  }, []);

  /* ========== Funkcije za gumbe ========== */

  // Prihvati sve nenužne (analytics & marketing)
  const handleAcceptAll = () => {
    const newPrefs = { analytics: true, marketing: true };
    setPreferences(newPrefs);
    savePreferences(newPrefs);
    loadAnalytics();
    loadMarketing();
    setShowBanner(false);
  };

  // Odbij sve nenužne
  const handleRejectAll = () => {
    const newPrefs = { analytics: false, marketing: false };
    setPreferences(newPrefs);
    savePreferences(newPrefs);
    // Ne učitavamo analitiku ni marketing
    setShowBanner(false);
  };

  // Ručno biranje (checkboxovi) -> Spremi postavke
  const handleSaveCustom = () => {
    // Ako je analytics=true, učitaj ga
    if (preferences.analytics) {
      loadAnalytics();
    }
    // Ako je marketing=true, učitaj marketing
    if (preferences.marketing) {
      loadMarketing();
    }

    savePreferences(preferences);
    setShowBanner(false);
  };

  // Promjena stanja pri kliku na checkbox
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setPreferences((prev) => ({ ...prev, [name]: checked }));
  };

  // Ako nema potrebe za bannerom, ne prikazuj ga
  if (!showBanner) return null;

  return (
    <div className="cookie-banner-advanced">
      <div className="banner-content">
        <h4>Postavke kolačića</h4>
        <p>
          Naša stranica koristi nužne kolačiće za osnovnu funkcionalnost. 
          Možete dopustiti i analitičke i marketinške kolačiće:
        </p>

        <div className="checkbox-group">
          <label>
            <input
              type="checkbox"
              name="analytics"
              checked={preferences.analytics}
              onChange={handleCheckboxChange}
            />
            Analitički kolačići (Google Analytics)
          </label>
          <label>
            <input
              type="checkbox"
              name="marketing"
              checked={preferences.marketing}
              onChange={handleCheckboxChange}
            />
            Marketinški kolačići (npr. Facebook Pixel, Google Ads)
          </label>
        </div>

        <div className="buttons">
          <button onClick={handleAcceptAll}>Prihvati sve</button>
          <button onClick={handleRejectAll}>Odbij sve</button>
          <button onClick={handleSaveCustom}>Spremi postavke</button>
        </div>

        <p style={{ marginTop: '10px' }}>
          <a href="/cookie-policy" className="cookie-link">
            Više informacija
          </a>
        </p>
      </div>
    </div>
  );
};

export default CookieBanner;
