import React from 'react';

const CookiePolicy = () => {
  return (
    <div style={{ maxWidth: '800px', margin: '20px auto', padding: '0 20px' }}>
      <h2>Politika kolačića</h2>
      <p>
        Naša stranica koristi kolačiće (cookies) kako bi poboljšala vaše korisničko iskustvo i
        pružila vam relevantne informacije. Ovdje objašnjavamo šta su kolačići, zašto ih
        koristimo i kako ih možete kontrolisati.
      </p>

      <h3>Šta su kolačići?</h3>
      <p>
        Kolačići su male tekstualne datoteke koje web stranica pohranjuje u vaš preglednik
        (browser). Oni služe za prepoznavanje vaših postavki i prethodnih aktivnosti.
      </p>

      <h3>Koje vrste kolačića koristimo?</h3>
      <ul>
        <li><strong>Neophodni kolačići:</strong> Omogućavaju osnovne funkcionalnosti, poput
          prijave i pamćenja vaših preferencija.</li>
        <li><strong>Analitički kolačići (opcionalno):</strong> Pomažu nam da mjerimo posjećenost i
          performance stranice (npr. Google Analytics).</li>
        <li><strong>Marketinški kolačići (opcionalno):</strong> Koriste se za praćenje i
          personalizirano oglašavanje.</li>
      </ul>

      <h3>Kako upravljati kolačićima?</h3>
      <p>
        Možete kontrolisati i/ili obrisati kolačiće u vašem pregledniku. Više informacija:
        <br />
        <a href="https://www.aboutcookies.org" target="_blank" rel="noopener noreferrer">
          aboutcookies.org
        </a>
      </p>

      <p>
        Ako onemogućite neke kolačiće, određene funkcije stranice možda neće raditi ispravno.
      </p>
    </div>
  );
};

export default CookiePolicy;
