import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useAuth } from './AuthContext';
import api from './api'; // Import API module
import './AdminProfile.css'; // Import CSS file for custom styles

const AdminProfile = () => {
  const [profile, setProfile] = useState(null);
  const { authState, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      if (authState.isAuthenticated) {
        const adminId = authState.user.sub; // Use the admin ID from authState
        try {
          const response = await api.get(`/administrators/${adminId}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
          console.log('Profile fetched:', response.data);
          setProfile(response.data);
        } catch (error) {
          console.error('Error fetching profile', error);
        }
      }
    };

    fetchProfile();
  }, [authState.isAuthenticated, authState.user.sub]);

  const handleLogout = () => {
    logout();
    navigate('/admin/login');
  };

  const handleEditProfile = () => {
    navigate('/admin/profile');
  };

  if (!authState.isAuthenticated || !profile) {
    return null;
  }

  const serverUrl = process.env.REACT_APP_SERVER_URL; // Use environment variable

  return (
    <Dropdown align="end">
      <Dropdown.Toggle as="div" id="dropdown-basic" className="custom-profile-toggle">
          {profile.profileImage ? (
            <img
              src={`${serverUrl}/profileImage/${profile.profileImage}`}
              alt={profile.username}
              className="profile-image rounded-circle"
              width="30"
              height="30"
            />
          ) : (
            <div className="no-image-container">
              <div className="no-image-circle" />
            </div>
          )}
          <span className="username">{profile.username}</span>
        </Dropdown.Toggle>


      <Dropdown.Menu>
        <Dropdown.Item onClick={handleEditProfile}>Edit Profile</Dropdown.Item>
        <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AdminProfile;
