import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';

// 1. Odaberi DOM element u koji 'mountaš' aplikaciju
const container = document.getElementById('root');

// 2. Kreiraj root
const root = createRoot(container);

// 3. Renderuj aplikaciju pomoću createRoot().render()

root.render(
  <React.StrictMode>
    <HelmetProvider>
    <App />
    </HelmetProvider>
  </React.StrictMode>
);


// (Neobavezno) Mjeri performanse
reportWebVitals();
