import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

// Inicijalizacija Axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Globalna varijabla za sprječavanje višestrukih refresh zahtjeva
let isRefreshing = false;

// Funkcija za osvježavanje Access Tokena
const refreshAccessToken = async () => {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) throw new Error('No refresh token available');

    const response = await axios.post(process.env.REACT_APP_REFRESH_TOKEN_URL, { refreshToken });

    localStorage.setItem('token', response.data.access_token);
    return response.data.access_token;
  } catch (error) {
    console.error('Refresh token failed:', error);

    // Odjava korisnika samo ako je greška vezana za autorizaciju
    if (error.response && error.response.status === 401) {
      handleLogout();
    }
    return null;
  }
};

// Funkcija za postavljanje Authorization zaglavlja
const setAuthHeader = (config, token) => {
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
};

// Axios interceptor za automatsko dodavanje tokena i CSRF tokena
api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token');
    const csrfToken = sessionStorage.getItem('csrfToken'); // Sigurnije od localStorage

    if (token) {
      try {
        const decodedToken = jwtDecode(token);

        // Provjera je li token istekao i osvježavanje ako treba
        if (decodedToken.exp * 1000 < Date.now()) {
          if (!isRefreshing) {
            isRefreshing = true;
            const newToken = await refreshAccessToken();
            isRefreshing = false;

            if (newToken) {
              setAuthHeader(config, newToken);
            }
          }
        } else {
          setAuthHeader(config, token);
        }
      } catch (error) {
        console.error('Invalid token:', error);
        handleLogout();
      }
    }

    // Dodavanje CSRF tokena ako postoji
    if (csrfToken) {
      config.headers['X-CSRF-Token'] = csrfToken;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Funkcija za odjavu korisnika
const handleLogout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  sessionStorage.removeItem('csrfToken'); // Uklanjanje CSRF tokena iz sessionStorage
  window.location.href = '/login'; // Preusmjeravanje na login stranicu
};

export default api;
