import React, { useEffect, useState, useRef } from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import UserProfile from './UserProfile';
import AdminProfile from './AdminProfile';
import { useAuth } from './AuthContext';
import './NavigationBar.css';
// Importovanje logo slike
import logo from './logo.png';

const NavigationBar = () => {
  const { authState, loading, logout } = useAuth();
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const navRef = useRef(null);

  useEffect(() => {
    console.log('NavigationBar: Current authState:', authState);
  }, [authState]);

  // Funkcija za otvaranje/zatvaranje menija
  const handleToggle = () => setExpanded(!expanded);

  // Zatvara meni kada se klikne na link
  const handleLinkClick = () => setExpanded(false);

  const handleLogout = () => {
    logout();
    setExpanded(false);
    navigate('/login');
  };

  // Zatvaranje menija kad se klikne izvan navigacijske trake
  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Ako klik nije unutar navbar elementa, zatvori meni
      if (navRef.current && !navRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    if (expanded) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }

    // Cleanup funkcija
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [expanded]);

  if (loading) {
    return null;
  }

  return (
    <nav ref={navRef} aria-label="Glavna navigacija" className={authState?.isAuthenticated ? 'auth-logged-in' : ''}>
      <Navbar
        bg="light"
        variant="light"
        expand="lg"
        expanded={expanded}
        onToggle={handleToggle}
        className="mb-4"
      >
        <Container>
          {/* Ubacivanje logotipa pre linka "Početna" */}
          <Link
            to="/"
            className="navbar-brand"
            onClick={handleLinkClick}
            title="Povratak na početnu stranicu"
          >
            <img 
              src={logo} 
              alt="Logo" 
              className="navbar-logo" // Možete prilagoditi stil u NavigationBar.css
            />
            Početna
          </Link>
          {!authState?.isAuthenticated && (
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={handleToggle}
              aria-label="Otvorite ili zatvorite meni"
              className="custom-navbar-toggler"
            />
          )}

          <div className="navbar-static-items">
            {authState?.isAuthenticated && (
              <Link
              to={authState.user?.role === 'administrator' ? "/admin/dashboard" : "/dashboard"}
              className="dashboard-link"
              onClick={handleLinkClick}
            >
              Upravljačka ploča
            </Link>
            
            )}

            {authState?.isAuthenticated && (
              <div className="custom-navbar-profile-icon" role="button" aria-label="Korisnički profil">
                {authState.user?.role === 'administrator' ? (
                  <AdminProfile onLogout={handleLogout} closeMenu={() => setExpanded(false)} />
                ) : (
                  <UserProfile onLogout={handleLogout} closeMenu={() => setExpanded(false)} />
                )}
              </div>
            )}

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                {!authState?.isAuthenticated && (
                  <>
                    <Link to="/register" className="nav-link" onClick={handleLinkClick} title="Registracija">
                      <Button variant="outline-light" className="me-2" aria-label="Registracija">
                        Registracija
                      </Button>
                    </Link>
                    <Link to="/login" className="nav-link" onClick={handleLinkClick} title="Prijava">
                      <Button variant="outline-light" className="me-2" aria-label="Prijava">
                        Prijava
                      </Button>
                    </Link>
                    <Link to="/contact" className="nav-link" onClick={handleLinkClick} title="Kontakt">
                      <Button variant="outline-light" aria-label="Kontakt">
                        Kontakt
                      </Button>
                    </Link>
                    <Link to="/admin/login" className="nav-link" onClick={handleLinkClick} title="Admin prijava">
                      <Button variant="outline-light" className="me-2" aria-label="Admin prijava">
                        Admin
                      </Button>
                    </Link>
                  </>
                )}
              </Nav>
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>
    </nav>
  );
};

export default NavigationBar;
